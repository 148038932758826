import axios from "axios";
const qs = require("qs");
export const BASE_URL = process.env.REACT_APP_BASE_URL;

export const request = axios.create({
  baseURL: BASE_URL,
  timeout: 30000,
  headers: {
    "Content-Type": "application/json",
  },
  paramsSerializer: function (params) {
    return qs.stringify(params, {
      encode: false,
    });
  },
});

import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import React, { useEffect, useState } from "react";
import Header from "./components/Header";
import SearchBox from "./components/SearchBox";
import Footer from "./components/Footer";
import Snow from "./components/Snow";
import { request } from "./api/adapter";
import {
  Spinner,
  Card,
  Modal,
  Button,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import rightIcon from "./assests/images/right.png";
import leftIcon from "./assests/images/left.png";
import giftbox from "./assests/images/giftbox.jpg";
import prise1 from "./assests/images/prise1.jpg";
import prise2 from "./assests/images/prise2.jpg";
import prise3 from "./assests/images/prise3.jpg";
import moment from "moment";
import "moment/min/locales";
import logo from "./assests/images/Logo.png";

export const ALL_WINNERS_CODE = process.env.REACT_APP_ALL_WINNERS_CODE;

var _ = require("lodash");

function App() {
  //----------------------hooks-----------------------
  const [all_numbers, set_all_numbers] = useState([]);
  const [app_loaded, set_app_loaded] = useState(false);
  const [loading_data, set_loading_data] = useState(false);
  const [show, setShow] = useState(false); //show modal for more info on carousel items

  //voucher more info
  const [voucher_amount, set_voucher_amount] = useState(0);
  const [voucher_number, set_voucher_number] = useState(0);
  const [voucher_desc, set_voucher_desc] = useState("");
  const [voucher_sponser, set_voucher_sponser] = useState("");
  const [voucher_address, set_voucher_address] = useState("");
  const [voucher_contact_person, set_voucher_contact_person] = useState("");
  const [voucher_telephone, set_voucher_telephone] = useState("");
  const [voucher_email, set_voucher_email] = useState("");

  //---------------------- APi calls ------------------

  async function get_all_winning_numbers() {
    set_loading_data(true);
    try {
      await request
        .get("vouchers/list?code=" + ALL_WINNERS_CODE, {})
        .then((Response) => {
          if (Response) {
            create_carousel_data(Response.data);
          }
        });
    } catch (error) {
      console.log(error);
      set_loading_data(false);
    }
  }

  //-------------------- component functions -------------------

  function create_carousel_data(data) {
    let result = _.chain(data)
      // Group the elements of Array based on `voucherDate` property
      .groupBy("voucherDate")
      // `key` is group's name (voucherDate), `value` is the array of objects
      .map((value, key) => ({ voucherDate: key, vouchers: value }))
      .value();
    set_all_numbers(result);

    set_app_loaded(true);
    set_loading_data(false);
  }

  useEffect(() => {
    var locale = window.navigator.userLanguage || window.navigator.language;
    moment.locale(locale);
    get_all_winning_numbers();
  }, []);

  function open_modal(
    amount,
    number,
    desc,
    sponser,
    street,
    postalCode,
    city,
    contactPerson,
    telephone,
    email
  ) {
    set_voucher_amount(amount);
    set_voucher_number(number);
    set_voucher_desc(desc);
    set_voucher_sponser(sponser);
    set_voucher_address(street + ", " + postalCode + " " + city);
    set_voucher_contact_person(contactPerson);
    set_voucher_telephone(telephone);
    set_voucher_email(email);
    setShow(true);
  }
  function close_modal() {
    setShow(false);
  }

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 6,
      slidesToSlide: 4, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
      slidesToSlide: 2, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Click for more info
    </Tooltip>
  );

  const CustomRightArrow = ({ onClick, ...rest }) => {
    const { onMove } = rest;
    return (
      <img
        src={rightIcon}
        style={{ width: "10px" }}
        className="react-multiple-carousel__arrow react-multiple-carousel__arrow--right"
        onClick={() => onClick()}
      />
    );
  };
  const CustomLeftArrow = ({ onClick, ...rest }) => {
    const { onMove } = rest;
    return (
      <img
        src={leftIcon}
        style={{ width: "10px" }}
        className="react-multiple-carousel__arrow react-multiple-carousel__arrow--left"
        onClick={() => onClick()}
      />
    );
  };

  const show_carousel = () => {
    if (all_numbers?.length > 0) {
      return (
        <>
        <div className="row justify-content-center mt-4 mb-4" style={{display:"none"}}>
              <div className="col-5">
                <div className="row justify-content-center mt-4 mb-4">
                  <div className ="col-sm-6 col-xs-12 justify-content-center mb-4"><h2>Samsung Galaxy Tablet</h2><span>Kalendernr: 854</span><p>Sponsor: Vodafone BPS Lauf</p></div>
                  <div className ="col-sm-6 col-xs-12 justify-content-center mb-4"><h2>1 Kochevent für 6 Personen</h2><span>Kalendernr: 1385</span><p>Sponsor: RistoSante</p></div>
                </div>
              </div>
          </div>
          <div className="row justify-content-center mt-4 carousel-title ">
            <h3>Alle Gewinner bis heute...</h3>
          </div>
          {all_numbers.map((item, i) => {
            return (
              <div className="row justify-content-center">
                <div className="col-10">
                  <div className="row justify-content-center mt-4 mb-4">
                    <div className="blue-line col-12">
                      <p>{moment(item.voucherDate).format("DD.MMMM YYYY")} </p>
                    </div>
                  </div>
                  <div className="mb-4 mt-4  ">
                    <Carousel
                      swipeable={true}
                      draggable={true}
                      showDots={true}
                      arrows
                      responsive={responsive}
                      // ssr={true} // means to render carousel on server-side.
                      infinite={true}
                      keyBoardControl={true}
                      customTransition="transform 300ms ease-in-out"
                      transitionDuration={100}
                      // containerClass="carousel-container"
                      removeArrowOnDeviceType={["tablet", "mobile"]}
                      customLeftArrow={<CustomLeftArrow />}
                      // customRightArrow={<ArrowRight />}
                      customRightArrow={<CustomRightArrow />}
                      // deviceType={this.props.deviceType}
                      dotListClass="custom-dot-list-style"
                      // itemClass="carousel-item-padding-40-px"
                    >
                      {item.vouchers.map((innerItem, j) => {
                        return (
                          <div className="row justify-content-center" key={j}>
                            <div className="">
                              <OverlayTrigger
                                placement="top"
                                delay={{ show: 250, hide: 400 }}
                                overlay={renderTooltip}
                              >
                                <Card
                                  onClick={() =>
                                    open_modal(
                                      innerItem.voucherAmount,
                                      innerItem.voucherNumber,
                                      innerItem.voucherDescription,
                                      innerItem.sponsorName,
                                      innerItem.street,
                                      innerItem.postalCode,
                                      innerItem.city,
                                      innerItem.contactPerson,
                                      innerItem.tel,
                                      innerItem.emailAddress
                                    )
                                  }
                                  border="light"
                                  style={{ width: "200px" }}
                                >
                                  <p className="giftbox-text">
                                    Kalendernr: {innerItem.voucherNumber}{" "}
                                  </p>
                                  <Card.Img variant="top" src={giftbox} />
                                  <Card.Body>
                                    <Card.Text>
                                      {/* <div className="row justify-content-center">
                                        <h6 className="giftbox-title ">
                                          {innerItem.voucherAmount}€
                                        </h6>
                                      </div> */}
                                      <div className="row justify-content-center">
                                        <p className="giftbox-text desc-ellpsis">
                                          {innerItem.voucherDescription}
                                        </p>
                                        <p className="giftbox-text">
                                          <span className="d-block">von</span>
                                          {innerItem.sponsorName}
                                        </p>
                                      </div>
                                    </Card.Text>
                                  </Card.Body>
                                </Card>
                              </OverlayTrigger>
                            </div>
                          </div>
                        );
                      })}
                    </Carousel>
                  </div>
                </div>
              </div>
            );
          })}
        </>
      );
    } else {
      return (
        <>
          <div className="row mt-4 mb-4 justify-content-center">No data</div>
        </>
      );
    }
  };

  return (
    <div>
      <Header></Header>
      <Snow />
      {/* -------------search box goes here------------------ */}
      <div className="main-container">
        <SearchBox />
        {/* -------------carousel box goes here------------------ */}
        <div>
          {app_loaded ? (
            <>{show_carousel()}</>
          ) : (
            <div className="row mt-4 mb-4 justify-content-center">
              {" "}
              <Spinner size="lg" animation="border" variant="warning" />
            </div>
          )}
          <Modal
            style={{ cursore: "pointer" }}
            centered={true}
            show={show}
            dialogClassName="modal-30w"
            onHide={close_modal}
          >
            <Modal.Header className="bg-darkblue">
              <Modal.Title>
                {" "}
                <span className="modal-title">Gewinn Details</span>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div className="row">
                  <div className="col-12 text-center font-weight-bold">
                    <span>Kalendernr: </span>
                    <span> {voucher_number}</span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 text-center mt-3">{voucher_desc}</div>
                </div>
                <div className="row ">
                  <div className="col-12  text-center mt-2">
                    <span className="d-block font-weight-bold">von</span>
                    <span> {voucher_sponser}</span>
                  </div>
                </div>
                <div className="row font-weight-bold">
                  <div className="col-12 text-center mt-3">
                    <span> {voucher_address}</span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12  text-center mt-2">
                    <span className="font-weight-bold">Telefon: </span>
                    <span> {voucher_telephone}</span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12  text-center mt-2">
                    <img src={logo} width="100" />
                    <span className="mt-3 d-block font-size-sm">
                      {" "}
                      Der Lions Club Lauf bedankt sich für die Unterstützung
                    </span>
                  </div>
                </div>
              </div>
            </Modal.Body>
            {/* <Modal.Footer>
              <div className="row justify-content-center">
                <Button
                  variant="secondary"
                  className="bg-darkblue"
                  onClick={close_modal}
                >
                  Close
                </Button>
              </div>
            </Modal.Footer> */}
          </Modal>
        </div>
        {/* -------------footer box goes here------------------ */}
      </div>
      <Footer />
    </div>
  );
}

export default App;

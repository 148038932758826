import React, { Component } from "react";
import logo from "../assests/images/Logo.png";
import tree from "../assests/images/christmas-tree.png";
function Header() {
  return (
    <div className="position-relative">
      <div className="header-bg">
        <div className="header-logo">
          <img src={logo} width="200" className="header-logo-mob--size" />
          <h2 className="brand--color brand-mob---fontsize">Adventskalender</h2>
          <span className="color-white">Lions Club Lauf</span>
        </div>
      </div>
      <div className="christmas-tree d-none d-md-block">
        <img src={tree} width="300" />
      </div>
    </div>
  );
}
export default Header;

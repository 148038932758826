import React, { useState } from "react";
import { Modal, Button, Spinner } from "react-bootstrap";
import { request } from "../api/adapter";
import logo from "../assests/images/Logo.png";

export const ONE_WINNER_CODE = process.env.REACT_APP_ONE_WINNER_CODE;

function SearchBox() {
  const [searched_result, set_searched_result] = useState([]);
  const [searched_number, set_searched_number] = useState("");
  const [show, setShow] = useState(false); //show modal
  const [loading_data, set_loading_data] = useState(false);
  const [is_winner, set_is_winner] = useState(false); //checks if the searched number is a winner or not

  async function search_winning_number(voucher_number) {
    // set_loading_data(true);
    try {
      await request
        .get(`vouchers/check/${voucher_number}?code=${ONE_WINNER_CODE}`, {})
        .then((Response) => {
          if (Response) {
            open_modal();
            set_searched_number("");
            if (Response.status === 200) {
              set_searched_result(Response.data);

              set_loading_data(false);
              set_is_winner(true);
            } else {
              set_is_winner(false);
            }
          }
        });
    } catch (error) {
      console.log(error);
      set_loading_data(false);
    }
  }
  function open_modal() {
    setShow(true);
  }
  function close_modal() {
    setShow(false);
    set_is_winner(false);
  }
  const handleChange = (event) => {
    set_searched_number(event.target.value);
  };
  const handleKeyPress = (event) => {
    // search with press enter
    if (event.keyCode == 13) {
      search_winning_number(searched_number);
    }
  };
  return (
    <div className="d-flex align-items-end flex-column">
      <div className="col-md-8 col-xs-12 search-box--color pt-3">
        {/* <h5 className="col-md-10"> */}

        {/* </h5> */}
        <div className="text-justify col-md-10">
          Herzlich Willkommen auf der Adventskalender Gewinnspiel Webseite des
          Lions Club Lauf an der Pegnitz.
          <br />
          Hier haben Sie die Möglichkeit zu überprüfen ob Ihr Kalender gewonnen
          hat. Bitte geben Sie hierzu die Nummer ihres Kalenders in das Suchfeld
          ein und drücken Sie das Lupensymbol. Bitte beachten Sie das nur
          Gewinne bis zum heutigen Datum angezeigt werden. Wir wünschen Ihnen
          viel Glück und bedanken uns herzlichst für die Unterstützung unserer
          Aktion
        </div>

        <div className="col-md-7  input-group md-form form-sm form-2  pt-5">
          <input
            className="form-control my-0 py-1"
            type="text"
            placeholder="Kalendernummer"
            aria-label="Search"
            value={searched_number}
            onChange={handleChange}
            onKeyDown={handleKeyPress}
          />
          {/* {loading_data ? ( */}
          <div
            className="input-group-append"
            onClick={() => search_winning_number(searched_number)}
          >
            <span className="input-group-text bg-darkblue">
              <i className="searchbox-icon" aria-hidden="true"></i>
            </span>
          </div>
          {/* ) : ( */}
          {/* <Spinner size="lg" animation="border" variant="warning" /> */}
          {/* )} */}
        </div>
      </div>
      <div></div>
      <Modal
        centered={true}
        show={show}
        dialogClassName="modal-30w"
        onHide={close_modal}
      >
        <Modal.Header className="bg-darkblue">
          <Modal.Title>
            {is_winner === true ? (
              <span className="modal-title">Gewinn Details!</span>
            ) : (
              <span className="modal-title ">
                Sie haben bisher leider nicht gewonnen!
              </span>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {is_winner === true ? (
            <div>
              <div className="row">
                <div className="col-12 text-center font-weight-bold">
                  <span>Kalendernr: </span>
                  <span> {searched_result.voucherNumber}</span>
                </div>
              </div>
              <div className="row">
                <div className="col-12 text-center mt-3">
                  {searched_result.voucherDescription}
                </div>
              </div>
              <div className="row ">
                <div className="col-12  text-center mt-2">
                  <span className="d-block font-weight-bold">von</span>
                  <span> {searched_result.sponsorName}</span>
                </div>
              </div>
              <div className="row font-weight-bold">
                <div className="col-12 text-center mt-3">
                  <span>
                    {" "}
                    <span>
                      {searched_result.street +
                        ", " +
                        searched_result.postalCode +
                        " " +
                        searched_result.city}
                    </span>
                  </span>
                </div>
              </div>
              <div className="row">
                <div className="col-12  text-center mt-2">
                  <span className="font-weight-bold">Telefon: </span>
                  <span> {searched_result.tel}</span>
                </div>
              </div>
              <div className="row">
                <div className="col-12  text-center mt-2">
                  <img src={logo} width="100" />
                  <span className="mt-3 d-block font-size-sm">
                    {" "}
                    Der Lions Club Lauf bedankt sich für die Unterstützung
                  </span>
                </div>
              </div>
            </div>
          ) : (
            <div>
              Es werden nur Gewinne bis zum heutigen Datum angezeigt! Wir
              wünschen Ihnen weiterhin viel Glück.
            </div>
          )}
        </Modal.Body>
        {/* <Modal.Footer>
          <Button
            variant="secondary"
            className="bg-darkblue"
            onClick={close_modal}
          >
            Close
          </Button>
        </Modal.Footer> */}
      </Modal>
    </div>
  );
}
export default SearchBox;
